










































































































import mixins from "vue-typed-mixins";
import { mapActions, mapGetters } from "vuex";
import { fireAuth } from "@/firebase";
import loadToStorage from "@/mixins/loadToStorage";
export default mixins(loadToStorage).extend({
  name: "MyGoals",
  mixins: [loadToStorage],
  mounted() {
    this.fetchGoalVersions(this.user.user);
  },
  data() {
    return {
      showPreviewModal: false,
      versionPreview: null,
      versionLoadedOk: false,
      logoutConfirmation: false,
      saveModalVisible: false,
    };
  },
  methods: {
    ...mapActions({
      setUser: "auth/setUser",
      updateGoalsVersion: "goals/updateGoalsVersion",
      fetchGoalVersions: "goals/fetchGoalVersions",
      removeGoalVersion: "goals/removeGoalVersion",
      saveVersion: "goals/saveVersion",
    }),
    confirmLogout() {
      this.logoutConfirmation = true;
    },
    saveAndLogout() {
      this.logoutConfirmation = false;
      this.saveModalVisible = true;
    },
    syncToCloud(docName: string) {
      this.loadFromLocalStorage();
      this.storeDocName(docName);
      this.saveVersion({
        user: this.user.user,
        data: {
          goals: this.goals,
          accomplishments: this.accomplishments,
          negativeList: this.negativeList,
          positiveList: this.positiveList,
        },
        docName: docName,
      });

      this.saveModalVisible = false;
      this.logout();
    },
    logout() {
      this.logoutConfirmation = false;
      fireAuth.signOut(fireAuth.getAuth()).then(() => {
        this.setUser({ user: {} });
        this.clearStorage();
        this.$router.replace("/");
      });
    },
    loadVersion(version: any) {
      this.clearStorage();
      this.storeAccomplishments(version.accomplishments);
      this.storePositives(version.positiveList);
      this.storeNegatives(version.negativeList);
      this.storeGoals(version.goals);
      this.storeDocName(version.docName);
      this.storeStep(1);
      this.versionLoadedOk = true;
    },
    makeNewList() {
      this.clearStorage();
      this.storeStep(1);
      this.$router.replace("/");
    },
    updateGoals(version: any) {
      this.showPreviewModal = true;
      this.versionPreview = version;
    },
    dropVersion(version: any) {
      this.removeGoalVersion({
        user: this.user.user,
        docId: version.docId,
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      goalVersions: "goals/getGoalVersions",
    }),
  },
});
